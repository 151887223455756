<template>
	<main class="bg-grid-gray-100/10">
		<section class="constraints flex flex-col items-center justify-center">
			<h1 class="mt-4 max-w-[750px] text-center text-4xl font-semibold lg:text-6xl">Write, Study, and Create Faster with AI</h1>
			<p class="mt-4 max-w-[600px] text-center text-2xl font-medium text-black">
				Streamline your reading, writing, and studying with intelligent tools built to save you time.
			</p>
			<div class="mt-8 flex flex-col items-center justify-center gap-4">
				<nuxt-link
					class="rounded-full bg-secondary px-8 py-3 font-semibold text-white transition-all hover:brightness-90 "
					to="/register">
					Join Scholarly for free
				</nuxt-link>
				<p class="text-xlg text-center font-medium text-black">Join 25,000+ students from top universities.</p>
			</div>
			<section class="mt-14 overflow-x-hidden text-center">
				<div class="schools flex flex-row items-center justify-center gap-12">
					<nuxt-img
						alt="Columbia"
						class="grayscale filter"
						format="webp"
						loading="lazy"
						src="/landing/schools/columbia.png"
						width="100px" />
					<nuxt-img
						alt="George Town"
						class="grayscale filter"
						format="webp"
						loading="lazy"
						src="/landing/schools/george_town.webp"
						width="100px" />
					<nuxt-img
						alt="Harvard"
						class="grayscale filter"
						format="webp"
						loading="lazy"
						src="/landing/schools/harvard.png"
						width="100px" />
					<nuxt-img
						alt="MIT"
						class="grayscale filter"
						format="webp"
						loading="lazy"
						src="/landing/schools/mit.png"
						width="100px" />
					<nuxt-img
						alt="Stanford"
						class="grayscale filter"
						format="webp"
						loading="lazy"
						src="/landing/schools/stanford.png"
						width="100px" />
					<nuxt-img
						alt="University of California, Los Angeles"
						class="grayscale filter"
						format="webp"
						loading="lazy"
						src="/landing/schools/ucla.png"
						width="100px" />
					<nuxt-img
						alt="University of Chicago"
						class="grayscale filter"
						format="webp"
						loading="lazy"
						src="/landing/schools/uni_chicago.png"
						width="100px" />
					<nuxt-img
						alt="USC"
						class="grayscale filter"
						format="webp"
						loading="lazy"
						src="/landing/schools/usc.png"
						width="100px" />
					<nuxt-img
						alt="Yale"
						class="grayscale filter"
						format="webp"
						loading="lazy"
						src="/landing/schools/yale.png"
						width="100px" />
				</div>
			</section>
			<div class="mt-14">
				<div
					:style="{
						transform: headerImageTransformationString,
					}">
					<nuxt-link to="/register">
						<video
							autoplay
							class="hidden w-full rounded-2xl border border-gray-100 shadow-sm lg:block"
							muted
							playsinline
							src="/landing/landing_recording.mp4" />
					</nuxt-link>
				</div>
			</div>
		</section>
		<!--		/**-->
		<!--		* Some Notes:-->
		<!--		* 	- High Quality pictures taken from Mac-->
		<!--		* 	- Use Light Mode for all-->
		<!--		* 	- First should be a video-->
		<!--		* Features:-->
		<!--		*-->
		<!--		* Read (Find & understand info in document in milliseconds)-->
		<!--		* 	- Scholarly Assistant in PDFs-->
		<!--		* 	- will require an image)-->
		<!--		*-->
		<!--		* Write:-->
		<!--		* 	- Edit & write with help from AI-->
		<!--		* 		- Show the ai text modification system-->
		<!--		*-->
		<!--		* Flashcards Quiz Creating-->
		<!--		* 	- Show how you can study using AI from flashcards-->
		<!--		*-->
		<!--		* & a lot more (Will have an image for each)-->
		<!--		* 	- PDF -> Flashcards-->
		<!--		* 	- AI Autocomplete-->
		<!--		* 	- AI Search (Global & Local)-->
		<!--		* 	- -->
		<!--		*/-->
		<section class="constraints mt-24 border-t pt-24">
			<h1 class="landing-section-title">Instantly Find Key Insights from Documents</h1>
			<p class="landing-section-subtitle">Use Scholarly Assistant to ask questions and instantly extract key details from your notes and PDFs.</p>
			<div class="landing-section-image-container">
				<nuxt-img
					alt="Learning"
					format="webp"
					loading="lazy"
					src="/landing/pdf_chat.png" />
			</div>
		</section>
		<section class="constraints mt-24 border-t pt-24">
			<h1 class="landing-section-title">Create and edit content with AI</h1>
			<p class="landing-section-subtitle">
				Get a little help from AI with text completion, and AI text editing. Highlight your text, and ask Scholarly Assistant to edit it, it's simple and fast.
			</p>
			<div class="landing-section-image-container">
				<nuxt-img
					alt="Learning"
					format="webp"
					loading="lazy"
					src="/landing/ai_text_edit.png" />
			</div>
		</section>
		<section class="constraints mt-24 border-t pt-24">
			<h1 class="landing-section-title">Study Smarter with AI-Generated Quizzes</h1>
			<p class="landing-section-subtitle">
				Automatically convert your flashcards into quizzes with multiple-choice, true/false, and short-answer questions. Let Scholarly tailor your study experience.
			</p>
			<div class="mt-12 grid grid-cols-3 gap-12">
				<div class="">
					<div class="ai-tool-color inline-block rounded-md">
						<AcademicCapIcon class="h-8 w-8" />
					</div>
					<div class="mt-1">
						<p class="text-xlg font-semibold">Quiz yourself with relevant options</p>
						<p class="text-[14px] text-black">Scholarly converts flashcards into quiz problems, automatically</p>
					</div>
				</div>
				<div class="">
					<div class="ai-tool-color inline-block rounded-md">
						<CheckCircleIcon class="h-8 w-8" />
					</div>
					<div class="mt-1">
						<p class="text-xlg font-semibold">Question Variety</p>
						<p class="text-[14px] text-black">Learn with True/False, Multiple Choice and Short Answer questions.</p>
					</div>
				</div>
				<div class="">
					<div class="ai-tool-color inline-block rounded-md">
						<SparklesIcon class="h-8 w-8" />
					</div>
					<div class="mt-1">
						<p class="text-xlg font-semibold">Get feedback on your answers</p>
						<p class="text-[14px] text-black">You always have a personal AI tutor to help you understand your mistakes.</p>
					</div>
				</div>
			</div>
			<div class="landing-section-image-container !mt-12">
				<nuxt-img
					alt="Learning"
					format="webp"
					loading="lazy"
					src="/landing/study_mode.png" />
			</div>
		</section>
		<section class="constraints mt-24 border-t pt-24">
			<h1 class="landing-section-title">There's More to Scholarly</h1>
			<p class="landing-section-subtitle">Explore the powerful tools that make Scholarly stand out and give you a competitive edge in your learning.</p>
			<div class="mt-12 grid grid-cols-2 gap-12">
				<div>
					<div class="relative h-[260px] overflow-hidden rounded-3xl border border-gray-150 bg-gray-100 pl-8 pt-8">
						<nuxt-img
							alt="Image Generation"
							class="rounded-tl-xl"
							format="webp"
							loading="lazy"
							src="/landing/image_generation.png" />
					</div>
					<div class="mt-4">
						<h1 class="mt-2 text-[18px] font-semibold">Image Generation</h1>
						<p class="text-[16px] text-black">Use AI to generate images from text, making it easier to create visually appealing content.</p>
					</div>
				</div>
				<div>
					<div class="relative h-[260px] overflow-hidden rounded-3xl border border-gray-150 bg-gray-100 pl-8 pt-8">
						<nuxt-img
							alt="Flashcards"
							class="rounded-tl-xl"
							format="webp"
							loading="lazy"
							src="/landing/flashcards.png" />
					</div>
					<div class="mt-4">
						<h1 class="mt-2 text-[18px] font-semibold">Flashcards</h1>
						<p class="text-[16px] text-black">Generate flashcards from text, PDFs, & more.</p>
					</div>
				</div>
				<div>
					<div class="relative h-[260px] overflow-hidden rounded-3xl border border-gray-150 bg-gray-100 pl-8 pt-8">
						<nuxt-img
							alt="AI Powered Search"
							class="rounded-tl-xl"
							format="webp"
							loading="lazy"
							src="/landing/ai_search.png" />
					</div>
					<div class="mt-4">
						<h1 class="mt-2 text-[18px] font-semibold">AI Powered Search</h1>
						<p class="text-[16px] text-black">Search smarter with AI to quickly find relevant information.</p>
					</div>
				</div>
				<div>
					<div class="relative flex h-[260px] flex-col overflow-hidden rounded-3xl border border-gray-150 bg-gray-100 pl-8 pt-8">
						<nuxt-img
							alt="Text Completion"
							class="rounded-tl-xl"
							format="webp"
							loading="lazy"
							src="/landing/text_completion.png" />
					</div>
					<div class="mt-4">
						<h1 class="mt-2 text-[18px] font-semibold">Text Completion</h1>
						<p class="text-[16px] text-black">Smart, context-aware text completion that helps you write faster and better.</p>
					</div>
				</div>
				<div>
					<div class="relative h-[260px] overflow-hidden rounded-3xl border border-gray-150 bg-gray-100 pl-8 pt-8">
						<nuxt-img
							alt="Link External Files"
							class="rounded-tl-xl"
							format="webp"
							loading="lazy"
							src="/landing/external_files.png" />
					</div>
					<div class="mt-4">
						<h1 class="mt-2 text-[18px] font-semibold">Link External Files</h1>
						<p class="text-[16px] text-black">Link external files to Scholarly Assistant and get context-aware responses.</p>
					</div>
				</div>
				<div>
					<div class="relative h-[260px] overflow-hidden rounded-3xl border border-gray-150 bg-gray-100 pl-8 pt-8">
						<nuxt-img
							alt="Youtube Integration"
							class="inset-center-xy absolute w-[150px]"
							format="webp"
							loading="lazy"
							width="200px"
							src="/landing/youtube.png" />
					</div>
					<div class="mt-4">
						<h1 class="mt-2 text-[18px] font-semibold">Youtube Integration</h1>
						<p class="text-[16px] text-black">Ask Scholarly to find videos about any topic, easily.</p>
					</div>
				</div>
			</div>
		</section>
		<section
			v-if="false"
			class="constraints mt-48">
			<h1 class="text-3xl font-bold">Where knowledge meets AI.</h1>
			<h2 class="mt-2 text-xl text-neutral-500">Utilize works created by other students, to enhance your learning experience.</h2>
		</section>
		<faq-section />
	</main>
</template>
<script lang="ts" setup>
import { AcademicCapIcon, CheckCircleIcon, SparklesIcon } from "@heroicons/vue/24/solid";

definePageMeta({
	title: "Write, Study, and Create Faster with AI - Scholarly",
	layout: "landing",
	name: "index",
});

useHead({
	title: "Write, Study, and Create Faster with AI - Scholarly",
});

const headerImageTransformation = reactive({
	perspective: 2000,
	translateX: 0,
	translateY: 0,
	scale: 1,
	rotate: 0,
	rotateX: 10,
	rotateY: 0,
	translateZ: 0,
});

const headerImageTransformationString = computed(() => {
	return `perspective(${headerImageTransformation.perspective}px) translateX(${headerImageTransformation.translateX}px) translateY(${headerImageTransformation.translateY}px) scale(${headerImageTransformation.scale}) rotate(${headerImageTransformation.rotate}deg) rotateX(${headerImageTransformation.rotateX}deg) rotateY(${headerImageTransformation.rotateY}deg) translateZ(${headerImageTransformation.translateZ}px)`;
});

function onScroll() {
	const scrollY = window.scrollY;

	headerImageTransformation.perspective = Math.max(2000 - scrollY * 0.03, 0);
	headerImageTransformation.rotateX = Math.max(10 - scrollY * 0.03, 0);
}

onMounted(() => {
	window.addEventListener("scroll", onScroll);

	getPosthog().capture("landing_page_viewed", {});
});

onUnmounted(() => {
	window.removeEventListener("scroll", onScroll);
});

/**
 * Some Notes:
 * 	- High Quality pictures taken from Mac
 * 	- Use Light Mode for all
 * 	- First should be a video
 * Features:
 *
 * Read (Find & understand info in document in milliseconds)
 * 	- Scholarly Assistant in PDFs
 * 	- will require an image)
 *
 * Write:
 * 	- Edit & write with help from AI
 * 		- Show the ai text modification system
 *
 * Flashcards Quiz Creating
 * 	- Show how you can study using AI from flashcards
 *
 * & a lot more (Will have an image for each)
 * 	- PDF -> Flashcards
 * 	- AI Autocomplete
 * 	- AI Search (Global & Local)
 * 	-
 */
</script>
<style>
/* Schools Fading  */

.schools img {
	opacity: 100%;
}

.schools img:nth-child(1) {
	opacity: 20%;
}

.schools img:nth-child(2) {
	opacity: 40%;
}

.schools img:nth-child(3) {
	opacity: 60%;
}

.schools img:nth-child(4) {
	opacity: 80%;
}

.schools img:nth-last-child(1) {
	opacity: 20%;
}

.schools img:nth-last-child(2) {
	opacity: 40%;
}

.schools img:nth-last-child(3) {
	opacity: 60%;
}

.schools img:nth-last-child(4) {
	opacity: 80%;
}

.landing-section-title {
	@apply max-w-[700px] text-[40px] font-semibold leading-[47px];
}

.landing-section-subtitle {
	@apply mt-2 max-w-[700px] text-[18px] font-medium;
}

.landing-section-image-container {
	@apply mt-6 rounded-xl bg-gray-150 p-2;
}

.landing-section-image-container img {
	@apply w-[1280px] rounded-lg;
}


</style>
